<template>
  <div class="company-settings" v-loading="loading">

    <div>
      Импорт Целей в секцию «Личные цели. Формируются самостоятельно. Проектная шкала» карты КПЭ
    </div>

    <import-modal
      entity="card_elements"
      close-on-success
      @import-succeed="importSucceed"
    >
      <template v-slot:reference>
        <el-button style="margin-top: 20px">
          Импорт
        </el-button>
      </template>
    </import-modal>


  </div>
</template>

<script>

import {companySettings} from "@/mixins/companySettings";
import {downloader} from "@/mixins/downloader";
import ImportModal from "@/components/exportImport/ImportModal.vue";

export default {
  name: "company-settings",
  components: {ImportModal},
  mixins: [companySettings],


  props: {},

  watch: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    importSucceed(){
      this.$notify.success({
        title: 'Завершено',
        message: 'Импорт завершен успешно'
      });
    },
  }
}
</script>


<style lang="scss">

</style>